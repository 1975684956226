@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url(./fonts/Rubik-BoldItalic.ttf) format("ttf");
}
